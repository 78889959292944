import { Inject, Injectable, InjectionToken, LOCALE_ID } from '@angular/core';
import { castEnum } from '@core/enum';
import { isNotNil } from '@core/is-not-nil';
import { LanguageCode, MultiLanguage, Nil } from '@model';
import { first, get, isNil, keys } from 'lodash-es';

import { MultiLanguageFallback } from './language.types';

export const MULTILANGUAGE_FALLBACK_MESSAGE =
  new InjectionToken<MultiLanguageFallback>('');

@Injectable()
export class LanguageService {
  public constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(MULTILANGUAGE_FALLBACK_MESSAGE)
    public fallback: MultiLanguageFallback,
  ) {}

  public getLanguage(): LanguageCode {
    return (
      castEnum(LanguageCode, this.locale.substring(0, 2)) ??
      LanguageCode.English
    );
  }

  public getValue(value: MultiLanguage | Nil): string {
    if (isNil(value)) {
      return '-';
    }

    const name = value[this.getLanguage()];

    if (isNil(name)) {
      if (isNotNil(this.fallback)) {
        return this.fallback(this.getLanguage());
      }

      const key = first(keys(value));
      if (isNil(key)) {
        return '';
      }
      return get(value, key, '');
    }
    return name;
  }
}
